import styled from '@emotion/styled';
import Link from 'next/link';
import AccountNavigation from 'src/components/AccountNavigation/AccountNavigation';
import useLang from 'src/services/language/useLang';
import { useTheme } from 'src/ui-kit/theme';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import Dropdown from '../Dropdown/Dropdown';
import AccountButtonContent from './AccountButtonContent';
import styles from './HeaderLogin.styles';
import LoginButton from './LoginButton';

export interface HeaderLoginProps {
  isLoggedIn: boolean;
}

export const AuthDropdownStyled = styled(Dropdown, {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  z-index: ${theme.base.zIndex.headerDropdown};
`);


const HeaderLogin = (props: HeaderLoginProps) => {
  const { isLoggedIn } = props;
  const lang = useLang();
  const theme = useTheme();

  return (
    <div className="HeaderLogin">
      {isLoggedIn ? (
        <div className="HeaderLogin__login">
          <div className="HeaderLogin__loginButton">
            <Link
              href={`/${lang}/account/`}
              data-marker="Header account"
              data-testid='account-button'
            >
              <AccountButtonContent />
            </Link>
          </div>

          <div className="HeaderLogin__accountDropdown">
            <AuthDropdownStyled
              theme={theme}
              label={<AccountButtonContent />}
              dataMarker="account navigation"
              position="right"
            >
              <div className="HeaderLogin__accountNavigation">
                <AccountNavigation
                  place="dropdown"
                />
              </div>
            </AuthDropdownStyled>
          </div>
        </div>
      ) : (
        <LoginButton dataMarker="Header login" />
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

export default HeaderLogin;
